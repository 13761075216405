<!--
 * @Author: guojiren 7755@123.com
 * @Date: 2023-03-24 17:24:23
 * @LastEditors: guojiren 7755@123.com
 * @LastEditTime: 2023-03-28 15:04:09
 * @FilePath: \xinghe-xinxi-pc\src\views\bid\noticeMessage\noticeDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <Wrapper>
    <div class="service">
      <DetailBanner titleType='2' title=""></DetailBanner>
      <water-title Chinese="招采投标" English="CORPORATE BUSINESS"></water-title>

      <div class="w">
        <div style="width: 100%">
          <div class="noticeCon">
            <div class="to_back" @click="navto">
              <i class="el-icon-back icon"></i>
            </div>
            <div class="mess_con">
              <h1 style="text-align: center;">{{ dicDetail.title }}</h1>
              <div class="mess_det" v-html="dicDetail.content"></div>
              <div class="fujian" v-if="dicDetail.file">
                <a :href="dicDetail.file"
                  ><img src="@/assets/imgs/fujian.svg" alt="" />附件</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
</template>
<script>
import DetailBanner from '@/views/sections/DetailBanner.vue'
import WaterTitle from '@/components/WaterTitle.vue'
import Wrapper from '@/components/Wrapper.vue'
import { getDicDetail } from '@/api/modules/bid'
export default {
  components: { DetailBanner,WaterTitle,Wrapper },
  data() {
    return {
      dicDetail: {},
      id: '',
    }
  },
  mounted() {
    // console.log(this.$route,'jjjjj');
    this.id = this.$route.query.id
    this.getDicDetail()
  },
  methods: {
    getDicDetail() {
      let getByIdParam = {
        id: this.id,
      }
      getDicDetail(getByIdParam).then((res) => {
        console.log(res, 'ress')
        if (res.code == 200) {
          this.dicDetail = res.data
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    navto() {
      this.$router.back()
    },
  },
}
</script>
<style scoped lang="less">
.noticeCon {
  box-shadow: 0 0 4px 2px #eee;
  width: 1190px;
  padding: 50px 30px;
  padding-bottom: 60px !important;
  margin: 40px auto;
  padding-bottom: 30px;
  height: 100%;
  .to_back {
    .icon {
      font-size: 24px;
      cursor: pointer;
      color: #666;
      font-weight: bold;
      &:hover {
        color: #1b86fb;
      }
    }
  }
  .mess_con {
    width: 90%;
    margin: 50px auto 20px;
    h1 {
      font-size: 20px;
      color: #555;
    }
    .mess_det {
      min-height: 300px;
      margin-top: 30px;
      border: 1px solid #eee;
      padding: 15px;
    }
    .fujian {
      width: 95%;
      margin: 20px auto 0;
      // margin-top: 20px;
      a {
        display: inline-block;
        line-height: 24px;
        color: #1b86fb;
        display: flex;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px !important;
          margin: auto 0;
        }
      }
    }
  }
}
</style>
